<template>
  <v-select :items="items" dense filled clearable v-model="value" attach multiple @change="isChanged"></v-select>
</template>

<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";
export default VueJS.extend({
  data() {
    return {
      value: null,
      items: [],
    };
  },
  methods: {
    getValue() {
      return this.value;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    isChanged() {
      this.value = this.value !== "" ? this.value : null;
    },
  },
  mounted() {
    this.value = this.params.value;
    this.items = this.params.cellEditorItems;
  },
});
</script>
