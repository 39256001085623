<template>
  <v-autocomplete
    class="text-body-2"
    :value="current"
    :items="candidate"
    :loading="loading"
    :item-value="itemVaule"
    :item-text="itemText"
    return-object
    :disabled="disabled"
    @change="onSelected"
    clearable
    hide-no-data
    filled
    dense
    :multiple="multiple"
    :attach="attach"
  ></v-autocomplete>
</template>
<script>
// import Vue from "vue";
import VueJS from "vue/dist/vue.js";
import CommonUtils from "../../../utils/CommonUtils";
export default VueJS.extend({
  data() {
    return {
      loading: null,
      candidate: [],
      current: null,
      itemVaule: null,
      itemText: null,
      attach: false,
      disabled: false,
      multiple: false,
    };
  },
  methods: {
    getValue() {
      return this.current;
    },
    isCancelBeforeStart() {
      return false;
    },
    isCancelAfterEnd() {
      return false;
    },
    onSelected(value) {
      if (Array.isArray(value)) {
        const self = this;
        this.current = value.map(function (elem) {
          if (CommonUtils.isObject(elem)) {
            return elem[self.itemVaule];
          } else {
            return elem;
          }
        });
      } else if (CommonUtils.isObject(value)) {
        this.current = value[this.itemVaule];
      } else {
        this.current = value;
      }
    },
  },
  mounted() {
    this.current = this.params.value;
    this.candidate = this.params.cellEditorItems;
    this.itemVaule = this.params.cellEditorValueId;
    this.itemText = this.params.cellEditorTextId;
    this.multiple = this.params.cellEditorMultiple;
  },
});
</script>
