<template>
  <v-date-picker
    no-title
    locale="ja"
    v-model="bindDate"
    type="date"
    :picker-date.sync="pickerDate"
    @input="onPickerChanged"
  >
    <div>
      <template>
        <v-btn-toggle v-model="dateSeason" color="primary">
          <v-btn
            small
            v-for="season in dateSeasons"
            :key="season.value"
            :value="season.value"
            v-text="season.text"
            @click="onSeasonChanged(season, $event)"
          ></v-btn>
        </v-btn-toggle>
      </template>
    </div>
  </v-date-picker>
</template>

<style>
.v-date-picker-table {
  height: auto !important;
}
</style>

<script>
// import Vue from "vue";
import moment from "moment";
import DateSeasons from "../../../consts/DateSeasons";

export default {
  data() {
    return {
      value: null,
      bindDate: null,
      dateSeason: null,
      pickerDate: null,
      hideAbout: null,
    };
  },
  computed: {
    dateSeasons: function () {
      return DateSeasons.all().filter((dataSeason) => {
        return !(this.hideAbout && this.hideAbout.indexOf(dataSeason.value) !== -1);
      });
    },
  },
  methods: {
    getValue() {
      return this.value;
    },
    isPopup() {
      return true;
    },
    initOfAbout(dateString) {
      const matches = /^(?<y>[0-9]{4}).(?<m>0[1-9]|1[0-2]).(?<d>[^0-9]+)$/.exec(dateString);
      if (matches != null && matches.length === 4) {
        const season = DateSeasons.all().find((s) => s.text === matches.groups.d || s.short === matches.groups.d);
        if (season) {
          this.dateSeason = season.value;
          this.pickerDate = `${matches.groups.y}-${matches.groups.m}`;
          this.value = `${matches.groups.y}/${matches.groups.m}/${matches.groups.d}`;
        } else {
          this.clear();
        }
      } else {
        this.initOfDate(dateString);
      }
    },
    initOfDate(dateString) {
      if (dateString == "未定") {
        const yyyymmdd = moment();
        this.bindDate = yyyymmdd.format("YYYY-MM-DD");
        this.value = dateString;
        this.pickerDate = yyyymmdd.format("YYYY-MM");
      } else {
        const yyyymmdd = moment(dateString, "YYYY/MM/DD");
        this.bindDate = yyyymmdd.format("YYYY-MM-DD");
        this.value = yyyymmdd.format("YYYY/MM/DD");
        this.pickerDate = yyyymmdd.format("YYYY-MM");
      }
    },
    clear() {
      this.bindDate = null;
      this.value = null;
      this.dateSeason = null;
      this.pickerDate = null;
      this.cleanPickerDate();
    },
    cleanPickerDate(force) {
      if (!this.pickerDate || force) {
        this.pickerDate = moment().format("YYYY-MM");
      }
      console.log(this.pickerDate);
    },
    onPickerChanged(e) {
      this.dateSeason = null;
      const yyyymmdd = moment(e, "YYYY/MM/DD").format("YYYY/MM/DD");
      this.value = yyyymmdd;
      this.params.api.stopEditing();
    },
    onSeasonChanged(season) {
      const text = DateSeasons.of(season.value);
      if (season.value === "pending") {
        this.value = `${text}`;
      } else {
        const [y, m] = this.pickerDate.split("-");
        this.value = `${y}/${m}/${season.short}`;
      }
      this.params.api.stopEditing();
    },
  },
  mounted() {
    this.value = this.params.value;
    this.hideAbout = this.params.cellEditorHideAbout;
    if (this.value && this.value.length > 0) {
      this.initOfAbout(this.value);
    } else {
      this.clear();
    }
  },
};
</script>
